import './App.css';
import {Command} from "./Command";
import {useState} from "react";
import {useToken} from "./useToken";
import {Login} from "./Login";


async function callFunction(func, token, args) {
    return fetch(process.env.REACT_APP_API_HOST + '/call/' + func, {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'},
        body: JSON.stringify(args),
    })
}

async function askForHelp(token) {
    return fetch(process.env.REACT_APP_API_HOST + '/describe', {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'},
    })
}

function parseCmd(cmd) {
    cmd = cmd.trim()
    const i = cmd.indexOf(' ')
    if (i === -1)
        return [cmd, ""]
    else
        return [cmd.substring(0, i), cmd.substring(i+1).trim()]
}

function parseHelp(h, setIntellisense) {
    let result = ""
    const intellisense = new Map();
    for (const [key, value] of Object.entries(JSON.parse(h))) {
        intellisense[key] = value
        result += key + ": " + value.split("\n")[0] + "\n"
    }
    setIntellisense(intellisense)
    return result
}

function App() {
    const {token, setToken} = useToken()
    let [commands, setCommands] = useState([{command: "", result: "", intellisense: ""}])
    const [help, setHelp] = useState("")
    const [intellisense, setIntellisense] = useState( new Map())

    if (!token || !token.startsWith("token-"))
        return <Login setToken={setToken} />

    if (!help) {
        setHelp("requested...")
        askForHelp(token).then(function(response)
        {
            if (!response.ok) {
                setHelp(response.statusText)
            } else {
                response.text().then(t => setHelp(parseHelp(t, setIntellisense))).catch(e => setHelp("Server problem: " + e.message))
            }
        }).catch(e => setHelp(e.message));
    }

    const onResult = (i, r) => {
        commands[i].result = r
        setCommands([...commands])
    }

    const handleSubmit = async(e, i) => {
        e.preventDefault()
        const cmd = e.target[0].value
        const [func, args] = parseCmd(cmd)

        if (func === 'logout') {
            setToken("")
            return
        }

        commands[i] = {command: cmd, result: "waiting...", intellisense: ""}
        if (i === (commands.length - 1))
            commands = [...commands, {command: "", result: "", intellisense: ""}]
        setCommands(commands);

        if (func === 'help') {
            onResult(i, help)
            return
        }

        const result = await callFunction(func, token, {args}).then(function(response)
        {
            if (!response.ok) {
                if (response.status === 498) {
                    console.log("token expired")
                    setToken("")
                }
                onResult(i, response.statusText)
            } else {
                return response.text()
            }
        }).catch(e => onResult(i, "Server problem: " + e.message));

        if (result)
            onResult(i, result)
    }

    function handleChanged(e, i) {
        const cmd = e.target.value
        commands[i].command = cmd;
        const func = cmd.split(" ")[0]
        if (func === "help") {
            commands[i].intellisense = "display available commands"
        } else {
            const desc = intellisense[func]
            commands[i].intellisense = desc ? desc : "";
        }
        setCommands([...commands])
    }

    const content = [];
    for (let i = 0; i < commands.length; i++) {
        let c = commands[i]
        content.push(<Command key={"command-"+i} command={c.command} result={c.result} intellisense={c.intellisense}
                              onSubmit={(e) => handleSubmit(e, i)}
                              onChanged={(e) => handleChanged(e, i)}

        />);
    }

    return (
          <div className="App code">
            {content}
          </div>
    )
}

export default App;
