import './App.css';
import './Login.css';
import {useState} from "react";

async function doLogin(credentials) {
    return fetch(process.env.REACT_APP_API_HOST + '/login', {
        method: 'POST',
        header: {'Content-Type': 'application/json'},
        body: JSON.stringify(credentials)
    })
}

export function Login({setToken}) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [err, setErr] = useState("");

    const handleSubmit = async(e) => {
        e.preventDefault()
        const token = await doLogin({username, password}).then(function(response)
        {
            if (!response.ok) {
                setErr(response.statusText)
            } else {
                return response.text()
            }
        })
        .catch(e => setErr("Server problem: " + e.message));
        if (token)
            setToken(token)
    }

    let errMsg;
    if (err)
        errMsg = <label className="err">{err}</label>
    else
        errMsg = <div/>

    return <div className="App code login">
        <form onSubmit={handleSubmit}>
            <div className="login-row">
                <label>email:</label>
                <input type="text" autoComplete="email" autoFocus required onChange={e => setUsername(e.target.value)}/>
            </div>
            <div className="login-row">
                <label>password:</label>
                <input type="password" autoComplete="current-password" required onChange={e => setPassword(e.target.value)}/>
            </div>
            <div className="login-submit">
                <input type="submit" hidden/>
            </div>
        </form>
        {errMsg}
    </div>
}
