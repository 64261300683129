import {useState} from 'react';

// https://betterprogramming.pub/how-to-authentication-users-with-token-in-a-react-application-f99997c2ee9d

export function useToken() {
    const getToken = () => {
        return localStorage.getItem('token')
        // return sessionStorage.getItem('token')
    }

    const [token, setToken] = useState(getToken())
    const saveToken = userToken => {
        localStorage.setItem('token', userToken)
        //sessionStorage.setItem('token', userToken)
        setToken(userToken)
    }
    return {
        setToken: saveToken,
        token
    }
}
