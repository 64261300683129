import './Command.css';
import {useState} from "react";

export function Command(props) {

    const [focused, setFocused] = useState(false)

    const prompt = "@:";

    let result = props.result;
    if (result) {
        if (result.startsWith("<")) {
            result = <iframe srcDoc={result} title="result"></iframe>
            // result = <div dangerouslySetInnerHTML={{__html: result}}/>
        } else {
            result = <pre>{result}</pre>
        }
    }

    let intellisense = <div/>
    if (props.intellisense && focused)
        intellisense = <span className="intellisense"><pre>{props.intellisense}</pre></span>

    const focusStyle = focused ? "enabled" : "disabled";

    return (
        <div className="command">
            <div className={"command-line " + focusStyle}>
                {intellisense}
                <div className={"prompt " + focusStyle}>{prompt}</div>
                <form onSubmit={props.onSubmit}>
                    <input type="text" value={props.command} autoFocus={true} autoCapitalize="none" autoCorrect="off" onChange={props.onChanged}
                    onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}/>
                </form>
            </div>
            <div className="result">{result}</div>
        </div>
    );
}

