import './App.css';
import './Login.css';
import {useState} from "react";
import {useToken} from "./useToken";
import {useNavigate} from "react-router-dom";


async function doLogin(credentials) {
    return fetch(process.env.REACT_APP_API_HOST + '/addUser', {
        method: 'POST',
        header: {'Content-Type': 'application/json'},
        body: JSON.stringify(credentials)
    })
}

export function AddUser() {
    const [username, setUsername] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");

    const [err, setErr] = useState("");

    const {setToken} = useToken()
    const navigate = useNavigate();

    const handleSubmit = async(e) => {
        e.preventDefault()
        const token = await doLogin({username, password1, password2}).then(function(response)
        {
            if (!response.ok) {
                setErr(response.statusText)
            } else {
                return response.text()
            }
        })
        .catch(e => setErr("Server problem: " + e.message));
        if (token) {
            setToken(token)
            navigate("/")
        }
    }

    let errMsg;
    if (err)
        errMsg = <label className="err">{err}</label>
    else
        errMsg = <div/>

    return <div className="App code login">
        <form onSubmit={handleSubmit}>
            <div className="login-row">
                <label>email:</label>
                <input type="text" autoComplete="email" autoFocus required onChange={e => setUsername(e.target.value)}/>
            </div>
            <div className="login-row">
                <label>password:</label>
                <input type="password" autoComplete="new-password" required onChange={e => setPassword1(e.target.value)}/>
            </div>
            <div className="login-row">
                <label>confirm:</label>
                <input type="password" autoComplete="new-password" required onChange={e => setPassword2(e.target.value)}/>
            </div>
            <div className="login-submit">
                <input type="submit" hidden/>
            </div>
        </form>
        {errMsg}
    </div>
}
